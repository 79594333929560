<template>
    <transition name="khgfk">
  <div class="plan-building-container">
      <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Замовити зворотній зв'язок</h5>
              <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
            </div>
            <div class="modal-body" v-if="modalSuccesed">
              Дякуємо, ми зв'яжемось з Вами найближчим часом!
            </div>
            <div class="modal-body" v-else>
              <form @submit.prevent="onSubmit">
                <div class="row mb-3">
                  <label for="inputEmail3" class="col-sm-6 text-right col-form-label">Ім’я</label>
                  <div class="col-sm-6">
                    <input type="text" v-model="clientContact" class="form-control"  />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword3" class="col-sm-6 text-right col-form-label">Телефон або е-мейл</label>
                  <div class="col-sm-6">
                    <input type="text" v-model="clientName" class="form-control"  />
                  </div>
                </div>
                <button type="submit" class="btn btn-primary">Відправити заявку</button>
              </form>
            </div>
            <!-- <div class="modal-footer">
            </div> -->
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" ref="backdrop"></div>
    <h2 class="plan-building-title plan-building-title-1">Виберіть поверх</h2>
    <h2 class="plan-building-title plan-building-title-2">який Вас цікавить</h2>
    <svg x="0px" y="0px" viewBox="0 0 1920 1080" class="plan-building-background">
      <image style="overflow:visible;" width="2880" height="1621" xlink:href="/assets/images/poverh-selector.jpg"  transform="matrix(0.6667 0 0 0.6667 0 0)" />
      <path @click="goToFloor(2)" class="plan-building-selector" d="M817.7,865.5l-170,5V782l40-3v-11l65.5-7l38,5l126-12.5v-11l197-21l36,9l222.5-21.5l221,59v98l-221-17.5
        l-222.5,7L929.7,862L817.7,865.5z"/>
      <path @click="goToFloor(3)" class="plan-building-selector" d="M687.7,779l-40,3v-90l40-5.5v-17l65-13l40,9.5l124.5-21.5v-18l197-35.5l36,14l222-38.5L1593.7,670v98l-221.5-59
        l-222,21.5l-36-9l-197,21v11l-126,12.5l-38.5-5l-65,7V779z"/>
      <path @click="goToFloor(4)" class="plan-building-selector" d="M687.7,686.5l-40,5.5v-89.5l40-9v-22l65-17.5l40,12.5L917.2,536v-23l197-51.5l36,16.5l222-56l221.5,149.5V670
        l-221.5-103.5l-222,38.5l-36-14l-197,35.5v18L792.7,666l-40-9.5l-65,13V686.5z"/>
      <path @click="goToFloor(5)" class="plan-building-selector" d="M687.7,593.5l-40,9l1-90.5l38.5-13v-24l65.5-22l40.5,12.5L916.7,426v-28l-4-2l203-69l39.5,22l217-69
        l221.5,193.5v98L1372.2,422l-222,56l-36-16.5l-197,51.5v23l-124.5,30.5l-40-12.5l-65,17.5V593.5z"/>
      <g class="plan-building-commerce">
      <path class="plan-building-selector plan-selector-disabled" d="M647.7,870.5V915l12.5-7.5h25h45.5l22.5-1.5h41.5l21.5,1.5l19,6l14,8l16,7.5l12.5,2l12.5,3l4,4l2.5,7v11.5l4,4
          h9l5.5,2l5,5.5v7l140.5,5v-12l180-7.5V989l102.5-6.5v22l29.5,2l221-33.5V866l-221-17.5l-222.5,7l-223,6.5l-126.5,4l-108,2.5
          L647.7,870.5z"/>
        <g class="plan-building-commerce-button-group" @click="modal.show()">
          <rect x="950" y="875" class="st1" width="300" height="70"/>
          <text transform="matrix(1 0 0 1 960 906)"><tspan x="0" y="0" class="st3 st4">Замовити консультацію стосовно </tspan><tspan x="38.5" y="21.6" class="st3 st4">комерційних приміщень</tspan></text>
        </g>
      </g>
    </svg>

  </div>
    </transition>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  data() {
    return {
      modal: null,
      modalSuccesed: false,
      clientName: '',
      clientContact: '',
    }
  },
  methods: {
    goToFloor(floor) {
      return this.$router.push(`/plan/floor/${floor}`);
      // return console.log(floor);
      // return this.$router.push('gallery');
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    onSubmit(id) {
      console.log("submit", id);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ clientName: this.clientName, clientContact: this.clientContact, clientType: "commerce" })
      };
      console.log(requestOptions)
      fetch("https://gentleman-park.com.ua/api/", requestOptions)
        .then(response => response.text())
        // .then(data => (this.postId = data.id));
        .then(this.modalSuccesed = true)
        .then(setTimeout(() => {  this.modal.hide() }, 3000))
        .then(this.modalSuccesed = false)
        .then(data => console.log(data));
    }
  },
  // beforeCreate: function() {
  //     document.body.className = 'plan-page';
  // }
  mounted() {
    this.modal = new Modal(this.$refs.exampleModal)
    setTimeout(() => {
      this.toggleBodyClass('addClass', 'plan-page')
      setTimeout(() => {  this.toggleBodyClass('addClass', 'selected-building') }, 10)
    }, 10)
  },
  destroyed() {
    setTimeout(() => {  this.toggleBodyClass('removeClass', 'selected-building') }, 10);
  }
}
</script>

<style lang="scss">
.st1 {
  background: #fff;
  fill: #fff;
}
.plan-building-commerce {
  .plan-building-selector {
    cursor: auto;
  }
  .plan-building-commerce-button-group {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    .plan-building-commerce-button-group {
      opacity: 1;
      cursor: pointer;
    }
  }
}
/* .plan-building-selector:hover + .plan-building-group-commerce-button {
  opacity: 1;
} */
.plan-building-container .modal-dialog {
  margin-top: 80px;
}
.plan-building-container .modal-backdrop {
  display: none;
}
.modal-open .plan-building-container .modal-backdrop {
  opacity: 0.5;
  display: block;
}
.plan-page.selected-building > .modal-backdrop {
  display: none;
}
</style>
